import { useMemo } from 'react'
import type { IDropdownOption } from '@frontend/pole-ui/lib/components/Dropdown'
import find from 'lodash/find'
import { useGetElevators, useGetRegions } from '@/api/kubik/monolith'
import { getOption, transformOptions } from '@/components/ui/fields/helpers/Dropdown'
import type { IRegion } from '@/types'
import { EVariablesKind } from '@/types'
import type { IHarvestSaleElevator, ITemplate } from '@/types/HarvestSaleLots'
import { EDeliveryBasisVariantId } from '@/types/HarvestSaleLots'
import { useUserStores } from '@/utils/hooks/useUserStores'

export const usePlacementOptions = (regionCode?: string, template?: ITemplate, deliveryBasisId?: EDeliveryBasisVariantId) => {
  const isWagonDeliveryBasis = deliveryBasisId === EDeliveryBasisVariantId.wagon
  const withTemplate = Boolean(template)
  const { elevator } = template || {}

  const { data: regionsData, isLoading: isRegionsLoading } = useGetRegions({}, { query: { staleTime: Infinity } })

  const { data: elevatorsData, isLoading: isElevatorsLoading } = useGetElevators(
    { regionCode: Number(regionCode) },
    { query: { enabled: regionCode !== undefined && !Number.isNaN(regionCode) } },
  )
  const regions = regionsData?.data || []
  const elevators = elevatorsData?.data || []
  const { locations, isLocationsLoading, userStores } = useUserStores()

  const region = withTemplate ? (find(regions, { code: Number(regionCode) }) as IRegion) : undefined

  const locationOptions: IDropdownOption<string>[] = useMemo(() => {
    let tempLocations = locations
    if (isWagonDeliveryBasis) tempLocations = locations.filter(location => location?.kind?.id === EVariablesKind.station)

    return transformOptions({ options: tempLocations, valueKey: 'id', labelKey: 'address' })
  }, [locations, isWagonDeliveryBasis])

  return {
    isRegionsLoading,
    isLocationsLoading,
    isElevatorsLoading,
    locationOptions,
    userStores,
    elevatorOptions: transformOptions({
      options: withTemplate && elevator ? [elevator] : (elevators as IHarvestSaleElevator[]),
      valueKey: 'id',
    }),
    regionOptions: withTemplate
      ? [getOption(region?.name || '', regionCode)]
      : transformOptions({
          options: regions,
          valueKey: 'code',
        }),
  }
}
