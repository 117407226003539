import loadable from '@loadable/component'

export { default as CultureSaleRequest } from './CultureSaleRequest'
export { default as Templates } from './Templates'

export const NoBuyersModal = loadable(
  /* webpackChunkName: "NoBuyersModal" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./NoBuyersModal'),
)
