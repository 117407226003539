import { homeBreadcrumb } from '@/components/ui/Breadcrumbs/constants'
import { EHarvestSaleRoute } from '@/types/EHarvestSaleRoute'

export const templatesPerPage = 4

export const cultureSaleBreadcrumbs = [homeBreadcrumb, { captionId: 'breadcrumbs.harvestSale', link: EHarvestSaleRoute.main }]
export const notAgrariancCultureSaleBreadcrumbs = [homeBreadcrumb, { captionId: 'breadcrumbs.harvestSale', link: EHarvestSaleRoute.lots }]

export const saleRequestFormVolumeLimits = {
  min: 25,
  max: 1000000,
}
