import type { FC } from 'react'
import React from 'react'
import { useSelector } from 'react-redux'
import { Button, LotPrices, LotQualities } from '@/components/ui'
import { pushHarvestGtmEvent } from '@/logic/metrika/harvest/gtmEvents'
import { getUserGtmInfo } from '@/logic/metrika/helpers'
import { getUserRegion } from '@/logic/userRegion'
import type { RootState } from '@/redux/interfaces'
import { formatDate, formatLocalizedString } from '@/utils/formatUtils'
import { useActionWithAuthorization } from '@/utils/hooks/useActionWithAuthorization'
import { getElevatorNameForTemplate } from '@/utils/outputs/lot'
import type { ILotProps } from './interfaces'
import { messages } from './messages'
import './styles.scss'

const mapState = (state: RootState) => ({
  userGtmInfo: getUserGtmInfo()(state),
  userRegion: getUserRegion()(state),
})

const Lot: FC<ILotProps> = ({ template, isAgrarian, openRequestingHarvestSaleModal, isMobileDevice, perUnitTextId }) => {
  const { userGtmInfo, userRegion } = useSelector(mapState)

  const { makeAuthorizedAction, isAuthenticated } = useActionWithAuthorization(() => {
    pushHarvestGtmEvent(
      {
        items: [template],
        pagePart: 'content',
        blockName: 'blockListingOutputs',
        eventCategory: 'interactions',
        eventAction: 'click',
        eventLabel: 'lotsOutputs',
        eventStatus: 'success',
        ecommerceType: 'add',
        eventEcommerce: 'addToCart',
        eventNonInteraction: '0',
        itemContext: 'previewLotsOutputs',
        actionField: {
          list: 'previewLotsOutputs',
        },
      },
      userRegion.name,
      userGtmInfo,
    )

    if (openRequestingHarvestSaleModal) openRequestingHarvestSaleModal(template)
  }, isAgrarian)
  const { buyer, culture, qualityIndicators } = template
  const elevatorDescription = getElevatorNameForTemplate(template)

  return (
    <>
      <div className="row">
        {template.validTo && (
          <div className="harvest-sale-lot-preview__validTo">
            <span className="validToDate">
              {formatLocalizedString(messages.validTo, { date: formatDate(template.validTo, 'dd.MM.YY') })}
            </span>
          </div>
        )}
        <div className="harvest-sale-lot-preview__validTo">
          <span className="validToDate">{messages.notice}</span>
        </div>
      </div>
      <h3 className="space-holder2 font_base font-weight_normal text_base-sm text_small-from-sm">{culture.name}</h3>
      {qualityIndicators && <LotQualities className="space-holder16 text_small" quality={qualityIndicators} />}

      <LotPrices
        className={`space-holder16 row-justify_between ${isMobileDevice ? 'row-wrap' : 'row_nowrap'}`}
        isAgrarian={isAgrarian}
        isMobileDevice={isMobileDevice}
        isStoreChosen={false}
        template={template}
        perUnitTextId={perUnitTextId}
      />

      {buyer.name}
      {elevatorDescription && <p className="space-holder-top2 space-holder16 color_pale-black">{elevatorDescription}</p>}

      <div className="row row-justify_end row_nowrap-from-sm space-holder-top-auto row-align_center space-holder-top-auto">
        {(!isAuthenticated || isAgrarian) && openRequestingHarvestSaleModal && (
          <Button modifiers={['green', 'small']} onClick={makeAuthorizedAction} className="harvest-sale-lot-preview__button">
            {messages.leaveRequest}
          </Button>
        )}
      </div>
    </>
  )
}

export default Lot
