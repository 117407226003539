import find from 'lodash/find'
import { useData } from '@/logic/data'
import type { IRegion, IStoreLocation } from '@/types'
import { EDeliveryBasisVariantId } from '@/types/HarvestSaleLots'
import type { TUseSettingRegionOptionHandlerOptions } from '@/types/TUseSettingRegionOptionHandlerOptions'
import { useMount } from '@/utils/hooks/useMount'
import { useUserStores } from '@/utils/hooks/useUserStores'

export const useChangingRegionHandler = (options: TUseSettingRegionOptionHandlerOptions) => {
  const { form, template, deliveryBasisId } = options
  const withTemplate = Boolean(template)
  const { elevator } = template || {}

  useMount(() => {
    if (elevator) {
      const { region } = elevator.location
      form.change('regionCode', region.code)
    }
  })

  const { data: regions = [] } = useData<IRegion[]>('regions')
  const { locations } = useUserStores()

  const storeChangedHandler = (regionCode: string) => {
    if (withTemplate) return

    const userRegion: IRegion | undefined =
      deliveryBasisId === EDeliveryBasisVariantId.pickup
        ? (find(locations, { id: regionCode }) as IStoreLocation | undefined)?.region
        : (find(regions, { code: regionCode }) as IRegion | undefined)

    if (userRegion) {
      const { code } = userRegion
      form.change('regionCode', code)
      form.change('deliveryOptions.elevatorId', undefined)
    }
  }

  return { storeChangedHandler }
}
