import React from 'react'
import type { IRegionsDeclinationsProps } from '@/pages/CulturesSaleLots/components/CultureSaleRequest/interfaces'
import { messages } from './messages'

const RegionsDeclinations = (props: IRegionsDeclinationsProps) => {
  const { regions } = props
  const lastNumOfRegions = regions?.toString().slice(-1)
  const fromTwoToFourRegions = ['2', '3', '4']
  const fromFiveToTenRegions = ['5', '6', '7', '8', '9', '0']

  const isOneRegion = lastNumOfRegions === '1'
  const isfromTwoToFourRegions = fromTwoToFourRegions.includes(lastNumOfRegions)
  const isfromFiveToTenRegions = fromFiveToTenRegions.includes(lastNumOfRegions)

  return (
    <>
      {isOneRegion && messages.cultureSale.oneRegion}
      {isfromTwoToFourRegions && messages.cultureSale.fromTwoToFourRegions}
      {isfromFiveToTenRegions && messages.cultureSale.fromFiveToTenRegions}
    </>
  )
}
export default RegionsDeclinations
