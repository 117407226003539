import React from 'react'
import { Form } from 'react-final-form'
import { useDispatch } from 'react-redux'
import { openModal } from '@/components/composed/Modal/actions'
import CultureSaleRequestMain from '@/pages/CulturesSaleLots/components/CultureSaleRequest/CultureSaleRequestMain'
import type { IRequestHarvestSaleByTemplateFormValues } from '@/pages/HarvestSaleLots/components/RequestHarvestSaleByTemplateModal/interfaces'
import { EDeliveryBasisVariantId, EOrderType } from '@/types/HarvestSaleLots'
import { memoizeObject } from '@/utils/helpers'
import { useActionWithAuthorization } from '@/utils/hooks/useActionWithAuthorization'
import { useCurrentRegion } from '@/utils/outputs/currentRegion'
import { useHarvestSaleLotsPageParams } from '@/utils/outputs/useHarvestSaleLotsPageParams'
import type { ICultureSaleRequestProps } from './interfaces'
import './styles.scss'

const CultureSaleRequest: React.FC<ICultureSaleRequestProps> = ({ isAgrarian }) => {
  const { region } = useCurrentRegion()
  const { cultureId } = useHarvestSaleLotsPageParams()
  const dispatch = useDispatch()
  const deliveryBasisId = EDeliveryBasisVariantId.pickup

  const { makeAuthorizedAction } = useActionWithAuthorization<(values: Partial<IRequestHarvestSaleByTemplateFormValues>) => void>(
    values => {
      dispatch(
        openModal({
          options: {
            dialogId: 'RequestHarvestSaleByTemplateModal',
          },
          contentOptions: {
            currentCultureId: values.cultureId,
            customInitialFormValues: {
              ...values,
              quality: values.quality || {},
              orderType: EOrderType.multiple,
              regionCode: values.deliveryOptions?.regionCode ? values.deliveryOptions?.regionCode : region?.code,
            },
            isOwnOffer: true,
          },
        }),
      )
    },
    isAgrarian,
  )

  return (
    <Form
      onSubmit={values => makeAuthorizedAction(values)}
      initialValues={{
        cultureId,
        deliveryOptions: memoizeObject({
          deliveryBasisId,
        }),
      }}
    >
      {({ handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit}>
            <div className="culture-sale-request">
              <CultureSaleRequestMain />
            </div>
          </form>
        )
      }}
    </Form>
  )
}

export default CultureSaleRequest
