import type { FC } from 'react'
import React from 'react'
import { useFormState } from 'react-final-form'
import { useGetApi } from '@/api/hooks'
import { ECommonApiUrl } from '@/api/urls'
import CultureSaleRequestForm from '@/pages/CulturesSaleLots/components/CultureSaleRequest/CultureSaleRequestForm'
import PricePredictionWidget from '@/pages/CulturesSaleLots/components/CultureSaleRequest/PricePredictionWidget'
import { EDeliveryBasisVariantId } from '@/types/HarvestSaleLots'
import { createRouterLink, getUrlWithQuery } from '@/utils/url'

const CultureSaleRequestMain: FC<{ cultureName?: string }> = props => {
  const { cultureName } = props
  const { values } = useFormState()
  const { cultureId, deliveryOptions } = values || {}
  const { deliveryBasisId } = deliveryOptions || []
  const currentRegion = values.regionCode ? values.regionCode : ''
  let regionId = values.regionCode
  let storesId = deliveryOptions.storeId
  const proteinPercentage = values.quality?.proteinFrom

  if (deliveryBasisId === EDeliveryBasisVariantId.pickup) {
    regionId = undefined
  } else {
    storesId = undefined
  }

  const buyersAndPricesUrl = getUrlWithQuery(createRouterLink(ECommonApiUrl.buyersAndPrices, { cultureId }), {
    regionId,
    storeId: storesId,
    proteinPercentage,
  })
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { data: buyersAndPrices } = useGetApi<any>(buyersAndPricesUrl, [storesId, currentRegion, deliveryBasisId, proteinPercentage])

  return (
    <>
      <CultureSaleRequestForm buyersAndPrices={buyersAndPrices} className="culture-sale-request__form" cultureName={cultureName} />
      <PricePredictionWidget buyersAndPrices={buyersAndPrices} />
    </>
  )
}

export default CultureSaleRequestMain
