import React from 'react'
import { useSelector } from 'react-redux'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import { EIconName, Icon } from '@frontend/pole-ui/lib/components/Icon'
import { isAuthenticated, isUserAgrarian } from '@/logic/auth/reducer'
import StoreFilter from '@/pages/CulturesSaleLots/components/StoreFilter/StoreFilter'
import Template from '@/pages/CulturesSaleLots/components/Template/Template'
import { templatesPerPage } from '@/pages/CulturesSaleLots/constants'
import type { RootState } from '@/redux/interfaces'
import { useDeviceType } from '@/utils/hooks/useDeviceType'
import { getHarvestSaleLotsUrl } from '@/utils/outputs/url'
import { useHarvestSaleLotsPageParams } from '@/utils/outputs/useHarvestSaleLotsPageParams'
import type { ITemplatesProps } from './interfaces'
import { messages } from './messages'
import './styles.scss'

const mapState = (state: RootState) => ({
  isAgrarian: isUserAgrarian()(state),
  isAuthenticate: isAuthenticated()(state),
})

const Templates: React.FC<ITemplatesProps> = ({ templates, openRequestingHarvestSaleModal, paginationInfoTotal }) => {
  const { isAgrarian, isAuthenticate } = useSelector(mapState)

  const { isDesktop, isMobile, isSmall } = useDeviceType()

  const { cultureId } = useHarvestSaleLotsPageParams()

  return (
    <section className="cultures-sales-lots-templates">
      <h3>{messages.offers}</h3>
      {isAuthenticate && <StoreFilter />}
      <div className={'cultures-sales-lots-templates-list'}>
        {templates.map(template => (
          <Template
            template={template}
            key={template.id}
            openRequestingHarvestSaleModal={openRequestingHarvestSaleModal}
            isAgrarian={isAgrarian}
            isMobileDevice={isMobile}
            isSmallMobileDevice={Boolean(isSmall)}
            isDeviceDesktop={isDesktop}
          />
        ))}
        {!isDesktop && (paginationInfoTotal ?? 0) > templatesPerPage && (
          <CommonLink
            url={getHarvestSaleLotsUrl(cultureId)}
            className="culture-sale-lot culture-sale-lot__show-all-offers text_center text_small row"
          >
            <div>
              <span>
                <Icon name={EIconName.ArrowRight2} />
              </span>
              <div>{messages.showAllOffersButton}</div>
            </div>
          </CommonLink>
        )}
      </div>
    </section>
  )
}

export default Templates
